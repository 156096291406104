<template>
  <AccountSharedLayout class="AccountSignupView" :show-language-switch="true" :show-legal="true">
    <span slot="title" v-t="`title.${from}`" />

    <div slot="main">
      <form @submit.prevent="registerByEmail()">
        <FormGroup
          v-model.trim="name"
          name="name"
          :label="$t('account.formLabels.name')"
          :v="$v.name"
          autocomplete="name"
          @input="resetErrors()"
        />

        <FormGroup
          v-model.trim="email"
          name="email"
          :label="$t('account.formLabels.email')"
          :v="$v.email"
          autocomplete="email"
          type="email"
          @input="resetErrors()"
        />

        <FormGroup
          v-model.trim="password"
          name="password"
          :label="$t('account.formLabels.password')"
          :v="$v.password"
          type="password"
          @input="resetErrors()"
        />

        <div class="submitButtonContainer">
          <div v-show="error" class="errorBox">
            <div v-if="errorMsg">
              {{ errorMsg }}
            </div>
            <div v-else v-t="'account.validationMessages.general'" />
          </div>
          <SubmitButton
            name="signup"
            class="submitButton"
            :label="$t('emailSignupButton')"
            :working="workingEmail"
            :disabled="$v.$invalid"
          />
        </div>
      </form>

      <!-- <template v-if="!hideFacebookLogin">
        <DividerWithText :label="$t('general.or')" />

        <div
          v-if="fbError"
          class="errorBox">
          An error occured while trying to sign up through Facebook. Please try again or
          <a
            href="#"
            @click="openSupport()">contact customer support</a>.
        </div>
        <SubmitButton
          class="facebookButton button"
          :label="$t('facebookSignupButton')"
          :working="workingFacebook"
          @click.native="registerThroughFacebook()" />
      </template> -->
    </div>

    <div slot="side" class="sideContent">
      <div class="benefits">
        <div v-t="'benefits.header'" class="benefitsHeader" />
        <div class="benefitsList">
          <div v-for="(item, i) of $t('benefits.list')" :key="i" class="benefitsItem">
            <svgicon class="benefitsItemCheckmark" icon="checkmark" />
            <span>{{ item }}</span>
          </div>
        </div>
      </div>

      <div class="spacer" />
      <div v-if="!hideLoginLink" class="links">
        <span v-t="'alreadyMember'" />&nbsp;
        <span class="loginLink --global-clickable" @click="gotoLogin()">{{ $t('alreadyMemberLogin') }}</span>
      </div>
    </div>

    <div slot="footer" class="terms" />
  </AccountSharedLayout>
</template>

<translations>
  title:
    fromMain: 'Start Improving Your Life at Super Speed – While Commuting or Exercising'
    fromMain_no: 'Tren deg til å bli bedre lynraskt – mens du pendler, trener eller tar en pause'
    fromMemogenius: 'Start Training your memory and 99 other life skills – while commuting or exercising'
    fromMemogenius_no: 'Tren hukommelsen og 99 andre livsområder – mens du pendler eller trener'
    fromMemolanguage: 'Start learning a language and 99 other life skills – while commuting or exercising'
    fromMemolanguage_no: 'Lær et språk og 99 andre livsområder – mens du pendler eller trener'

  benefits:
    header: 'Why you''ll love it'
    header_no: 'Ting du vil like'

    list:
      - 'Fun games with real life benefits'
      - '5 minute daily brain sprints'
      - 'Improve with proven techniques'
      - 'Improve, while commuting or exercising'
      - 'Refresh before your brain forgets'
      - 'Master 100 life skills with 1 master technique'

    list_no:
      - 'Morsomme spill med ekte resultater'
      - '5 minutters daglige hjernesprinter'
      - 'Bli bedre, med de beste teknikkene'
      - 'Bli bedre, mens du pendler eller trener'
      - 'Repeter rett før hjernen glemmer'
      - 'Mestre 100 livsområder med 1 mesterteknikk'

  alreadyMember: 'Already a member?'
  alreadyMember_no: 'Allerede medlem?'
  alreadyMemberLogin: 'Login'
  alreadyMemberLogin_no: 'Logg inn'

  emailSignupButton: 'Signup'
  emailSignupButton_no: 'Meld deg inn'

  facebookSignupButton: 'Signup with Facebook'
  facebookSignupButton_no: 'Meld deg inn med Facebook'
</translations>

<script>
import AccountSharedLayout from '../components/AccountSharedLayout'
import FormGroup from '../components/FormGroup'
import SubmitButton from '../components/SubmitButton'
import { required, minLength, email } from 'vuelidate/lib/validators'
import DividerWithText from '../components/DividerWithText'

export default {
  components: { AccountSharedLayout, FormGroup, SubmitButton, DividerWithText },
  props: {
    voucherAfterSignup: {
      type: Boolean,
      default: false,
    },
    hideLoginLink: {
      type: Boolean,
      default: false,
    },
    hideFacebookLogin: {
      type: Boolean,
      default: false,
    },
    voucher: {
      type: String,
    },
    language: {
      type: String,
    },
  },
  data() {
    return {
      workingFacebook: false,
      workingEmail: false,
      from: 'fromMain',
      name: '',
      email: '',
      password: '',

      errorMsg: '',
      error: false,
      fbError: false,
    }
  },
  mounted() {
    this.plan = this.$route.params.plan
    this.currency = this.$route.params.currency
    const lang = this.language || this.$route.params.language || this.$route.query.language
    if (lang) {
      this.$store.dispatch('moduleApp/setLocale', lang)
    }
  },
  methods: {
    resetErrors() {
      this.errorMsg = ''
      this.error = false
      this.fbError = false
    },
    async registerByEmail() {
      this.workingEmail = true

      let nameparts = this.name.split(' ')
      let firstName = nameparts.shift()
      let lastName = nameparts.join(' ')
      let language = this.$store.getters['moduleApp/locale']
      let currency = language === 'no' ? 'nok' : 'usd'
      try {
        await this.$store.dispatch('moduleAuth/register', {
          email: this.email,
          username: this.email,
          password: this.password,
          firstName,
          lastName,
          language,
          currency,
        })
      } catch (err) {
        this.errorMsg = err.message
        if (this.errorMsg === 'Username already taken') {
          this.errorMsg = 'Email already taken'
        }
        this.error = true
        return
      } finally {
        this.workingEmail = false
      }
      this.onSignupSuccess('email')
    },
    async registerThroughFacebook() {
      this.workingFacebook = true
      try {
        await this.$store.dispatch('moduleAuth/authenticate', 'facebook')
      } catch (err) {
        this.fbError = true
        return
      } finally {
        this.workingFacebook = false
      }
      this.onSignupSuccess('facebook')
    },

    async onSignupSuccess(provider) {
      window.dataLayer.push({ event: 'signup', provider: provider })
      // this.storage.setItem('product', this.product);
      if (this.voucher) {
        await this.registerWithVoucher(this.voucher)
        this.$router.push({ path: '/' })
      } else if (this.voucherAfterSignup) {
        this.$router.push({ name: 'AccountVoucherView' })
      } else if (this.plan !== undefined) {
        this.$router.push({
          name: 'AccountCheckoutView',
          params: { plan: this.plan },
        })
      } else {
        this.$router.push({ path: this.$route.query.next || '/' })
      }
    },
    async registerWithVoucher(voucher) {
      this.workingEmail = true

      try {
        // await this.$store.dispatch('moduleAuth/subscribe', {
        //   subscription: { 'trial': true }, // plan_id ?
        //   customer: {},
        //   coupon: voucher
        // })
        await this.$store.dispatch('moduleAuth/updateMe', { roles: [voucher] })
      } catch (err) {
        console.log('registerWithVoucher err', err) // eslint-disable-line no-console
        return
      } finally {
        this.workingEmail = false
      }
    },
    gotoLogin() {
      let next = this.$route.query.next
      if (this.voucher) {
        next = `/partner/${this.voucher}`
      }
      this.$router.push({ name: 'AccountLoginView', query: { next } })
    },
    openSupport() {
      this.$intercom.open()
    },
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
}

// email = '';
// plan = 'free-trial';
// product = '';
// from = 'from_main';
//
// canActivate(params) {
//   if (this.auth.isAuthenticated()) {
//     if (this.auth.isSubscribed() || params.plan === 'free-trial') {
//       return new RedirectToRoute('home');
//     } else {
//       return new RedirectToRoute('checkout', {plan: params.plan, product: params.product});
//     }
//   }
//   return true;
// }
//
// activate(params, routeConfig) {
//   this.plan = params.plan || this.plan;
//   this.email = (params.email || '').trim();
//   this.auth.setInitialUrl(this.redirectUrl());
//
//   this.options = Object.assign({}, routeConfig.settings || {});
//
//
//   if ({'memogenius': true, 'memolanguage': true}[params.product]) {
//     this.from = 'from_' + params.product;
//   }
//   this.product = params.product || this.product;
//
//   let twNext = encodeURI(`${window.location.protocol}//${window.location.hostname}/oauth`);
//   this.twitter_signup_url = `${settings.authConfig.baseUrl}/auth/twitter?next=${twNext}`;
//
//
//   if (params.currency) {
//     this.auth.setCurrency(params.currency);
//   }
//   if (params.language) {
//     return this.auth.setLanguage(params.language);
//   }
// }

// redirectUrl() {
//   if (this.next) {
//     return this.next;
//   }
//   if (this.plan === 'free-trial') {
//     return 'home';
//   }
//   return 'checkout/' + this.plan;
// }
</script>

<style lang="scss" scoped>
.AccountSignupView {
}

.errorBox {
  padding: 1em;
  background-color: #edc8c5;
  color: #c38a8a;
  margin-bottom: 1em;
}

.spacer {
  flex: 1;
}

.submitButtonContainer {
  margin-top: 2em;
}
.submitButton {
  width: 100%;
}

.button {
  background-color: #3b5998;
  color: white;
  padding: 1em 2em;
  border-radius: 2em;
  text-align: center;
}

.FormGroup {
  &:not(:first-child) {
    margin-top: 1em;
  }
}

.benefits {
  background-color: whitesmoke;
  padding: 1em;
}
.benefitsHeader {
  font-size: 120%;
  font-weight: 600;
}
.benefitsList {
  margin-top: 1em;
  font-size: 90%;
}
.benefitsItem {
  margin-bottom: 0.8em;
}
.benefitsItemCheckmark {
  fill: green !important;
  // greenButtonBackground
  height: 1em !important;
  width: 1em !important;
  margin-right: 0.5em;
}

.sideContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.links {
  a {
    text-decoration: none;
    color: #5fcf80;
  }
}
.loginLink {
  color: #5fcf80;
}
.facebookButton {
  width: 100%;
}

.AccountSignupView.useMobileLayout {
  .benefits {
    display: none;
  }
}
</style>
